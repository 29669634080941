import { Helmet } from 'react-helmet-async';
// @mui
import {
  Container,
  Stack,
  Card,
  FormControlLabel,
  Button,
  Modal,
  TextField,
  Switch,
  // Box,
  Grid,
} from '@mui/material';
import { useState, useReducer } from 'react';
import { SectionTitle, TitleComponent, DeviceComponent } from 'src/components/thurcomponents/index';
import { createUser } from '../../repository/users';
import ThurAlert from '../../components/alert/alert';
// import { Link as RouterLink } from 'react-router-dom';
// sections
// mock

const classes = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 3,
    padding: 10,
    ['sm']: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  textField: {
    margin: 1,
    ['sm']: {
      marginRight: 1,
    },
  },
  button: {
    margin: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#ccc',
    borderRadius: 10,
    boxShadow: 5,
    padding: '2,3,4',
    outline: 'none',
  },
};

const initialDeviceData = {
  deviceId: '',
  deviceName: '',
};

const initState = {
  active: false,
  email: '',
  activePlan: '',
  deviceToken: '',
  isAdmin: false,
  isPremium: '',
  devices: [],
  otpVerified: true,
  activeDevice: initialDeviceData,
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return {
        ...state,
        ...action.payload,
      };
    case 'ADD_DEVICE':
      return {
        ...state,
        devices: [...state.devices, action.payload],
      };
    case 'REMOVE_DEVICE':
      // const regs = state.devices.splice(action.payload, 1);
      return {
        ...state,
        devices: [...action.payload],
      };
    case 'UPDATE_DEVICE':
      // console.log(state.devices);
      return {
        ...state,
        devices: [...action.payload],
      };
    default:
      return;
  }
};
// const operationState = {

export default function CreateUserPage() {
  const [state, dispatch] = useReducer(reducer, initState);
  
  const [deviceData, setDeviceData] = useState(initialDeviceData);
  const [open, setOpen] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [response, setResponse] = useState(null);
  const [updateDevice, setUpdateDevice] = useState(false);

  const [rIndex, setDeviceIndex] = useState(0);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setEnabled(false);
    console.log(state);
    createUser(state)
      .then((res) => {
        handleResponse(res);
        dispatch({ type: 'SET_FORM_DATA', payload: initState });
      })
      .catch((e) => {
        console.log('error', e);
        handleResponse(e);
      });
  };

  const handleResponse = (res) => {
    setResponse(res);
    setEnabled(true);
  };

  const handleFormChange = (event) => {
    dispatch({ type: 'SET_FORM_DATA', payload: { [event.target.name]: event.target.value } });
    console.log(state.country);
  };

  const handleDeviceFormChange = (event) => {
    setDeviceData({
      ...deviceData,
      [event.target.name]: event.target.value,
    });
  };

  const handleToggle = (event) => {
    dispatch({ type: 'SET_FORM_DATA', payload: { [event.target.name]: event.target.checked } });
  };

  const handleAddDeviceClick = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const AddDeviceToList = (event) => {
    event.preventDefault();
    dispatch({ type: 'ADD_DEVICE', payload: deviceData });
    setOpen(false);
  };

  const updateDeviceToList = (event) => {
    event.preventDefault();
    const modify = state.devices;
    modify[rIndex] = deviceData;

    dispatch({ type: 'UPDATE_DEVICE', payload: modify });
    setOpen(false);
    // setDeviceData(initialDeviceData);
    setUpdateDevice(false);
  };

  //removes device from list
  const removeIndex = (index) => {
    const rg = state.devices;
    rg.splice(index, 1);
    dispatch({ type: 'REMOVE_DEVICE', payload: rg });
  };

  const editDevice = (index) => {
    setUpdateDevice(true);
    setDeviceData(state.devices[index]);
    setDeviceIndex(index);
    setOpen(true);
  };
  return (
    <>
      <Helmet>
        <title> Dashboard: Create User </title>
      </Helmet>

      <Container maxWidth="xl">
        <SectionTitle title={'Create VPN'} text={'Go Back'} />

        {response && <ThurAlert severe={response.status ? 'success' : 'error'} message={response.message} />}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} spacing={3}>
            <Card sx={{ padding: 0 }}>
              <form onSubmit={handleFormSubmit} style={{ px: 0, py: 2 }}>
                <TitleComponent title="User Data" />

                <Stack direction={'row'} spacing={6} sx={{ padding: 0, width: '100%', mb: 4 }}>
                  <TextField
                    className={classes.textField}
                    label="Email"
                    name="email"
                    value={state.email}
                    onChange={handleFormChange}
                    required
                    variant="standard"
                    width={500}
                    fullWidth
                  />
                </Stack>
                <Stack direction={'row'} spacing={6} sx={{ padding: 0, width: '100%', mb: 3 }}>
                  <FormControlLabel
                    control={
                      <Switch color="primary" name="isPremium" checked={state.isPremium} onChange={handleToggle} />
                    }
                    label="Premium"
                  />
                  <FormControlLabel
                    control={<Switch color="primary" name="active" checked={state.active} onChange={handleToggle} />}
                    label="Status"
                  />
                </Stack>
                <Stack direction={'row'} spacing={2} sx={{ width: '100%', padding: 0, mt: 3 }}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!enabled}
                  >
                    Create
                  </Button>
                  <Button className={classes.button} variant="contained" color="primary" onClick={handleAddDeviceClick}>
                    Add Device
                  </Button>
                </Stack>
              </form>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ alignItem: 'center', justifyContent: 'center', px: 2, py: 2 }}>
              <TitleComponent title="Devices" />

              {state.devices.map((device, index) => {
                return (
                  <DeviceComponent
                    device={device}
                    key={index}
                    onDelete={() => removeIndex(index)}
                    onEdit={() => {
                      editDevice(index);
                    }}
                  />
                );
              })}
            </Card>
          </Grid>
        </Grid>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleModalClose}
          aria-labelledby="add-device-title"
          aria-describedby="add-description"
        >
          <Card
            direction={'row'}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              // bgcolor: 'background.paper',
              // border: '2px solid #000',
              // boxShadow: 24,
              // pt: 2,
              // px: 4,
              // pb: 3,
            }}
          >
            {/* <Typography variant="h6" sx={{ mb: 5 }}>
                Enter Country Data
              </Typography> */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              mb={5}
              mt={5}
              sx={{ backgroundColor: 'white' }}
            >
              <form onSubmit={updateDevice ? updateDeviceToList : AddDeviceToList}>
                <Stack direction={'column'} spacing={3}>
                  <Stack direction={'row'} spacing={2}>
                    <TextField
                      className={classes.textField}
                      label="Device Name"
                      name="deviceName"
                      value={deviceData.deviceName}
                      onChange={handleDeviceFormChange}
                      required
                      variant={'standard'}
                    />
                    <TextField
                      className={classes.textField}
                      label="Device Id"
                      name="deviceId"
                      value={deviceData.deviceId}
                      onChange={handleDeviceFormChange}
                      required
                      variant={'standard'}
                    />
                  </Stack>

                  <Stack direction={'row'} spacing={2}>
                    <Button className={classes.button} variant="contained" type="submit" color="secondary">
                      {updateDevice ? 'Update' : 'Add'}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </Card>
        </Modal>
      </Container>
    </>
  );
}
