import { connect, errorHandler, handler } from '../utils/ThurVpnNet';
import axios from 'axios';
export const getUserData = async () => {
  // token = (await getItem('token')) ?? (await getItem('secureToken'));
  
  try {
    const res = await connect().get('users/get-user');
    const result = await handler(res);
    console.log(axios.defaults.headers.common['Authorization'])
    if (result != null && result.data != null) {
      return result.data;
    }

    return {};
  } catch (e) {
    return errorHandler(e);
  }
};

export const getUsers = async () => {
  // token = (await getItem('token')) ?? (await getItem('secureToken'));
  try {
    const res = await connect().get('admin/get-users');
    const result = await handler(res);
    if (result != null && result.data != null) {
      return result.data;
    }

    return {};
  } catch (e) {
    return errorHandler(e);
  }
};


export const deleteUser = async(id)=>{
  try{
    
    const res = await connect().delete(`users/delete/${id}`);
    return handler(res);

  }catch(e){
    return errorHandler(e)
  }
}


export const getUserById = async (id) => {
  // token = (await getItem('token')) ?? (await getItem('secureToken'));
  try {
    const res = await connect().get(`users/get/${id}`);
    const result = await handler(res);
    if (result != null && result.data != null) {
      return result.data;
    }

    return {};
  } catch (e) {
    return errorHandler(e);
  }
};


export const sendOtp = async(data)=>{
  try{
    const res = await connect().post('users/send-otp',{
      ...data
    });
    return handler(res);

  }catch(e){
    return errorHandler(e)
  }
}
export const createUser = async(data)=>{
  try{
    const res = await connect().post('users/create',{
      ...data
    });
    return handler(res);

  }catch(e){
    return errorHandler(e)
  }
}
export const updateUser = async(data)=>{
  
  console.log({
    ...data,
    id: data._id
  })
  try{
    const res = await connect().put('users/update',{
      ...data,
      id: data._id
    });
    return handler(res);

  }catch(e){
    return errorHandler(e)
  }
}