// import { Stack } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectContinentComponent = ({ value = 'Asia', setValue = () => {} }) => {
  
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-helper-label">Continent</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={value}
        label={"Continent"} 
        onChange={(val)=>setValue(val.target.value)}
        fullWidth
        variant={'outlined'}
        size={'small'}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={'Europe'}>Europe</MenuItem>
        <MenuItem value={'Asia'}>Asia</MenuItem>
        <MenuItem value={'North America'}>North America</MenuItem>
        <MenuItem value={'South America'}>South America</MenuItem>
        <MenuItem value={'Africa'}>Africa</MenuItem>
        <MenuItem value={'Antarctica'}>Antarctica</MenuItem>
        <MenuItem value={'Australia/Oceania'}>Australia/Oceania</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectContinentComponent;
